import {ZonedDateTime} from "@variocube/app-ui";
import missingImage from "../assets/missing-image.png";
import {Api} from "../libs/Api";
import {Model} from "./models";
import {Page} from "./types";

export interface Item {
	uuid: string;
	name: string;
	description: string;
	model: Model;
	images: string[];
	stored?: {cubeId: string; boxNumber: string};
}

export class ItemWrapper {
	readonly item: Item;

	constructor(item: Item) {
		this.item = item;
	}

	get imageUrl(): string {
		if (this.item.images && this.item.images.length > 0) {
			return this.item.images[0];
		}
		return this.item.model.imageUrl || missingImage;
	}
}

export class ItemsProvider {
	readonly api: Api;

	constructor(api: Api) {
		this.api = api;
	}

	async availableItems(
		tenantId: string,
		availableFrom: ZonedDateTime,
		availableUntil: ZonedDateTime
	): Promise<Page<Item>> {
		const url = `/tenants/${tenantId}/items?unpaged=true&availableFrom=${toISO(availableFrom)}&availableUntil=${toISO(
			availableUntil
		)}`;
		return this.api.get<Page<Item>>(url, "application/vnd.items.paged+json");
	}

	async availableItemsForModel(
		tenantId: string,
		modelUuid: string,
		availableFrom: ZonedDateTime,
		availableUntil: ZonedDateTime
	): Promise<Page<Item>> {
		const url = `/tenants/${tenantId}/items?modelUuid=${modelUuid}&availableFrom=${toISO(
			availableFrom
		)}&availableUntil=${toISO(availableUntil)}`;
		return this.api.get<Page<Item>>(url, "application/vnd.items.paged+json");
	}

	async get(tenantId: string, itemUuid: string) {
		const url = `/tenants/${tenantId}/items/${itemUuid}`;
		return this.api.get<Item>(url);
	}
}

function toISO(availableFrom: ZonedDateTime) {
	return availableFrom.toInstant(); // ISO String
}
