import {Api} from "../libs/Api";

interface IpInfo {
	ip: string;
	hostname: string;
	city: string;
	region: string;
	country: string;
	loc: string;
	org: string;
	postal: string;
	timezone: string;
}

export async function ipInfo(): Promise<IpInfo> {
	const api = new Api("/");
	return await api.get<IpInfo>("/public/ip-info");
}
